.site-nav-mobile {
	position: relative;
	float: right;
	margin-top: 14px;
	z-index: 110;
	.btn {
		width: 70%;
	}
	a.btn {
		font-family: @mainFont;
		color: @brown;
	}
}

nav.slideout-menu {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 0;
	width: 256px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	display: none;
	background-color: @sepia;
	ul li {
		padding: 8px 15px;
		border-top: solid 1px lighten(@sepia, 10%);
		border-bottom: solid 1px darken(@sepia, 10%);
		.clear-list;
		a:link {
			.white-link;
		}
		a:visited {
			.white-link;
		}
		a:hover {
			.white-link-hover;
		}
		.fa {
			margin-top: 3px;
			float: right;
		}
		.fa-negative {
			margin-top: -6px;
		}
		ul {
			margin-top: 5px;
			li {
				padding: 4px 15px;
				border-top: none;
				border-bottom: none;
				.fa {
					float: left;
					margin-right: 10px;
				}
			}
		}
	}
	ul li:hover {
		background-color: darken(@red, 10%);
	}
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

.slideout-panel {
	position:relative;
	z-index: 1;
	background-color: @bodyColor;
	.box-shadow (-1px, 0px, 2px, 0.3);
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.slideout-panel {
		.box-shadow (0px, 0px, 0px, 0) !important;
	}
	
	.site-nav {
		margin-left: 0;
		float: right;
		ul {
			li {
				float: left;
				list-style: none;
				padding-bottom: 26px;
				a {
					font-size: 14px;
					text-transform: uppercase;
					font-family: @mainFont;
					margin: 15px 15px 38px 15px;
				}
				a:link {
					color: @black;
				}
				a:visited, a:hover {
					color: @black;
				}
				ul.site-nav-child {
					display: none;
				}
				.dropdown-menu {
					right: 0 !important;
					left: auto !important;
					background-color: transparent !important;
					border: none !important;
					padding: 0px !important;
					margin-top: 5px !important;
				}
			}
			li.active {
				a {
					border-bottom: solid 3px @softBlue;
					padding-bottom: 10px;
				}
			}
			li.has-child {
				a:after {
					content: "";
					display: block;
					background: url("../images/caret_down.png") no-repeat;
					width: 6px;
					height: 4px;
					float: right;
					margin: 8px 10px 15px -6px;
				}
			}
			li.has-child:hover {
				ul.site-nav-child {
					margin-top: 32px;
					background-color: @white;
					display: block;
					position: absolute;
					li {
						float: none;
						padding: 10px 0px;
						border-bottom: solid 1px darken(@white, 10%);
						ul.site-nav-child {
							display: none;
						}
						a {
							padding: 0px;
						}
						a:after {
							display: none;
						}
					}
					li:hover {
						background-color: darken(@white,5%);
					}
					li:last-child {
						border-bottom: none;
					}
					li.has-child {
						a:after {
							content: "";
							display: block;
							background: url("../images/caret_right.png") no-repeat !important;
							width: 4px;
							height: 6px;
							float: right;
							margin: 8px 10px 0px -6px;
						}
					}
					li.has-child:hover {
						ul.site-nav-child {
							margin-top: -33px;
							background-color: darken(@white,5%);
							display: block;
							position: absolute;
							left: 100%;
							li {
								border-left: solid 1px darken(@white, 15%);
								border-bottom: solid 1px darken(@white, 15%);
								width: 100%;
								a {
									text-transform: none;
									white-space: nowrap;
									width: auto;
									height: auto;
								}
								a:after {
									display: none;
								}
							}
							li:hover {
								background-color: darken(@white,10%);
							}
						}
					}
				}
			}
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
}


