.site-header {
	.stripe-top {
		width: 100%;
		height: 10px;
		background-image: url(../images/top-color-stripe.png);
		background-repeat: no-repeat;
		background-position: top right;
		background-color: @softGreen;
	}
	background-color: @white;
	position: fixed;
	z-index: 1000;
	width: 100%;
	box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
	h2 {
		margin-top: 15px;
		font-size: 24px;
		font-weight: normal;
	}
	.site-logo {
		float: left;
		margin: 20px 0px;
		text-align: center;
		img {
			width: 160px;
		}
	}
}

.header-menu-container {
	position: relative;
	margin-top: 0px;
	z-index: 100;
}



/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.site-header {
		.site-logo {
			float: none;
			margin: 23px 0px;
			text-align: center;
			img {
				width: auto;
			}
		}
	}
	.header-menu-container {
		margin-top: 10px;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.site-header {
		.site-logo {
			float: left;
			text-align: left;
		}
	}
	.header-menu-container {
		margin-top: 32px;
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
}