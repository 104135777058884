/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/


.flexslider {

  margin: @flexslider-margin;
  background: @flexslider-bg;
  border: @flexslider-border;
  position: relative;
  zoom: 1;

  .border-radius( @flexslider-border-radius );
  .box-shadow( @flexslider-box-shadow-x-axis, @flexslider-box-shadow-y-axis, @flexslider-box-shadow-blur, @flexslider-box-shadow-color, @flexslider-box-shadow-inset);

  .slides {

    zoom: 1;

  img {
    height: auto;
    -moz-user-select: none;
  }
  }
}

.flex-viewport {

  max-height: @flex-viewport-max-height;

  .transition();

  .loading &{

    max-height: @flex-viewport-loading-max-height;

  }
}

.carousel li {

  margin-right: 5px;

}


//
// Direction Nav
//

.flex-direction-nav {

  *height: 0;


  //
  // Global styles
  //

  a  {

    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: @flex-direction-nav-color;
    text-shadow: @flex-direction-nav-text-shadow;

    .transition( 0.3s, ease-in-out );

    &:before  {

      font-family: "@{font-name}";
      font-size: 40px;
      display: inline-block;
      content: @flex-direction-nav-icon-prev;
      color: @flex-direction-nav-icon-color;
      text-shadow: @flex-direction-nav-icon-text-shadow;

    }

    &.flex-next:before {

      content: @flex-direction-nav-icon-next;

    }

  }


  //
  // Prev
  //

  .flex-prev {

    left: -50px;

  }


  //
  // Next
  //

  .flex-next {

    right: -50px;
    text-align: right;

  }


  //
  // Hover states
  //

  .flexslider:hover &{

    .flex-prev {

      opacity: 0.7;
      left: 10px;

      &:hover{

        opacity: 1;

      }

    }

    .flex-next {

      opacity: 0.7;
      right: 10px;

      &:hover{

        opacity: 1;

      }

    }

  }


  //
  // Disabled
  //

  .flex-disabled {

    opacity: 0!important;
    filter: alpha(opacity=0);
    cursor: default;
    z-index: -1;

  }
}


//
// Pause/Play
//

.flex-pauseplay a {

  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;

  &:before {

    font-family: "@{font-name}";
    font-size: 20px;
    display: inline-block;
    content: @flex-pauseplay-icon-pause;

  }

  &:hover {

    opacity: 1;

  }

  &.flex-play:before {

    content: @flex-pauseplay-icon-play;

  }

}


//
// Control Nav
//

.flex-control-nav {

  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;

  li {

    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;

  }

}


//
// Control paging
//

.flex-control-paging li {

  a {

    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0,0,0,0.5);
    cursor: pointer;
    text-indent: -9999px;

    .box-shadow( 0, 0, 3px, rgba(0,0,0,0.3), inset );
    .border-radius( 20px );

    &:hover {

      background: #333;
      background: rgba(0,0,0,0.7);

    }

    &.flex-active {

      background: #000;
      background: rgba( 0, 0, 0, 0.9 );
      cursor: default;

    }
  }
}


.flex-control-thumbs {

  margin: @flex-control-thumbs;
  position: static;
  overflow: hidden;

  li {

    width: 25%;
    float: left;
    margin: 0;

  }

  img {

    width: 100%;
    height: auto;
    display: block;
    opacity: .7;
    cursor: pointer;
    -moz-user-select: none;

    .transition();

    &:hover {opacity: 1;}

  }

  .flex-active {

    opacity: 1;
    cursor: default;

  }
}