.landing {
	h2 {
		color: @white;
		margin-top: 0;
		margin-bottom: 20px;
	}
	h5 {
		color: @white;
		margin-bottom: 20px;
	}
}

.product-select-container {
	margin-top: 80px;
	width: 100%;
	height: 100%;
	overflow: auto;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
	.product-select {
		float: left;
		width: 100%;
		text-align: center;
		background-position: center center;
		background-size: cover;
		a {
			font-size: 24px;
			vertical-align: middle;
			display: block;
			width: 100%;
			min-height: 120px;
		}
		a:link {
			color: @white;
		}
		a:visited {
			color: @white;
		}
		a:hover {
			color: @white;
		}
	}
	.cat1 {
		background-image: url(../images/cat-sport.jpg);
		a {
			padding-top: 45px;
			background-color: rgba(119,191,96,0.8);
		}
	}
	.cat1:hover {
		a {
			background-color: rgba(119,191,96,0.2);
		}
	}
	.cat2 {
		background-image: url(../images/cat-office.jpg);
		a {
			padding-top: 30px;
			background-color: rgba(154,192,193,0.8);
		}
	}
	.cat2:hover {
		a {
			background-color: rgba(154,192,193,0.2);
		}
	}
	.cat3 {
		background-image: url(../images/cat-resident.jpg);
		a {
			padding-top: 45px;
			background-color: rgba(241,178,109,0.8);
		}
	}
	.cat3:hover {
		a {
			background-color: rgba(241,178,109,0.2);
		}
	}
}

.logo-bottom {
	margin-top: 15px;
	margin-bottom: 15px;
	img {
		width: 29%;
		float: left;
		margin-right: 10px;
		margin-bottom: 10px;
	}
}






/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.landing {
		h2 {
			margin-bottom: 40px;
		}
		h5 {
			margin-bottom: 40px;
		}
	}
	.product-select-container {
		margin-top: 95px;
		.product-select {
			width: 33.3333%;
			a {
				font-size: 36px;
				min-height: 700px;
			}
		}
		.cat1 {
			a {
				padding-top: 350px;
			}
		}
		.cat2 {
			a {
				padding-top: 320px;
			}
		}
		.cat3 {
			a {
				padding-top: 350px;
			}
		}
	}
	.logo-bottom {
		img {
			width: auto;
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.product-select-container {
		.product-select {
			a {
				font-size: 48px;
			}
		}
		.cat1 {
			a {
				padding-top: 300px;
			}
		}
		.cat2 {
			a {
				padding-top: 270px;
			}
		}
		.cat3 {
			a {
				padding-top: 300px;
			}
		}
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	
}