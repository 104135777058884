footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	padding-top: 20px;
	background-color: @sepia;
	color: @brown;
	.copyright-company {
		p {
			text-align: left;
			.fa {
				margin-right: 10px;
			}
		}
		color: @brown;
	}
	.copyright {
		text-align: center;
		margin: 15px 0;
		a.btn {
			padding: 10px 15px;
			border-radius: 5px;
			background-color: darken(@sepia, 20%);
			color: @white;
			margin-top: -10px;
			.fa {
				margin-right: 10px;
			}
		}
		a.btn:link {
			color: @white;
		}
		a.btn:visited {
			color: @white;
		}
		a.btn:hover {
			color: @white;
			background-color: darken(@sepia, 30%);
		}
	}
}
.clear-footer {
	height: 140px;
}


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	footer {
		.copyright {
			text-align: right;
			margin: 0;
			a.btn {
				margin-top: 0px;
			}
			p {
				text-align: right;
			}
		}
	}
	.clear-footer {
		height: 61px;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	footer {
		.copyright {
			a.btn {
				margin-top: -10px;
			}
		}
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	
}