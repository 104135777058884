* {
  margin: 0;
  padding: 0;
}

/*Typography Style*/
li,p,a, h1, h2, h3, h4, h5, h6 {
	font-family: @mainFont;
}
li strong, p strong, a strong, h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
	font-family: @mainFontBold;
}

h1 {
	font-size: @font-size-h1 - 10;
	line-height: floor((@font-size-h1 * @line-height-base) - 15);
}
h2 {
	font-size: @font-size-h2 - 10;
	line-height: floor((@font-size-h2 * @line-height-base) - 10);
}
h3 {
	font-size: @font-size-h3 - 8;
	line-height: floor((@font-size-h3 * @line-height-base) - 10);
}
h4 {
	font-size: @font-size-h4 - 5;
	line-height: floor((@font-size-h4 * @line-height-base) - 5);
}
h5 {
	font-size: @font-size-h5 - 2;
	line-height: floor((@font-size-h5 * @line-height-base) - 2);
}
h6 {
	font-size: @font-size-h6;
	line-height: floor((@font-size-h6 * @line-height-base));
}
p {
	font-size: @font-size-base - 2;
	line-height: @line-height-computed - 2;
	margin-bottom: 10px;
}
ul,
ol {
	margin-top: 0;
	margin-bottom: 0;
	ul,
	ol {
		margin-bottom: 0;
	}
}
ul li, ol li {
	font-size: @font-size-base - 2;
	line-height: @line-height-computed - 2;
	text-align: justify;
	margin-bottom: 0px;
}

a:link {
	color: @pgnBlue;
}
a:visited {
	color: @pgnBlue;
}
a:hover {
	color: lighten(@pgnBlue, 10%);
	text-decoration: none;
}

/*Text Color*/
span.red {
	color: @red;
}

/*Background Color*/
.background-grey {
	background-color: darken(@white, 20%);
}

/*Button*/
.btn-custom-white, a.btn-custom-white {
	background-color: @white;
	color: @black;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	font-size: 18px;
}
.btn-custom-white:hover, a.btn-custom-white:hover {
	background-color: @red;
	color: @white;
}

html {
	height: 100%;
}
body {
	height: 100%;
	background-color: @bodyColor;
	color: @black;
}
.site-wrapper {
	min-height: 100%;
	width: @fullWidth;
	overflow: hidden;
}
.site-wrapper-background {
	width: 100%;
	height: 600px;
	position: absolute;
	z-index: -1;
	.gradient;
}
.site-content {
	background-color: #ddd;
	margin-bottom: 30px;
}
section#home {
	margin-top: 82px;
	position: relative;
	z-index: 0;
}

/*Section Setting*/
.inner-padding {
	padding: 30px 0;
}



/*Slider*/
.slide-caption {
	margin-top: 0px;
	padding: 0 60px;
	h1 {
		color: @white;
		font-family: @mainFont;
		text-transform: uppercase;
		font-size: 18px;
		line-height: 21px;
		text-align: left;
	}
	h4 {
		color: @white;
		margin-bottom: 30px;
		font-size: 12px;
		line-height: 16px;
		white-space: normal;
		margin-bottom: 10px;
	}
	hr {
		width: 150px;
		border-top: solid 3px @red;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	
}
.background-slide {
	background-image: url(../images/slide-bg.png);
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: 1400px;
	width: 100%;
	height: 100%;
	left: 0px !Important;
}
.background-overlay {
	width: 100%; 
	height: 100%; 
	background-color: rgba(0,0,0,0.5); 
	left: 0px !important;
}
.caption {
	iframe {
		border: none;
	}
}

/*Background*/
.white-background {
	background-color: @white;
}
.gray-background {
	background-color: darken(@white, 10%);
}
.lightblue-background {
	background-color: @pgnLightBlue;
}




/*Smart Forms*/
.smart-forms {
	margin-top: 0px; 
	label {
		font-family: @mainFont;
	}
	.section {
		margin-bottom: 0px;
	}
	.select > select::-ms-expand { 
		display: none; 
	}
	.select {
		.arrow2 {
			position: absolute;
			top: 9px;
			right: 4px;
			width: 24px;
			height: 24px;
			color:#9F9F9F;
			pointer-events:none;
			z-index:16;
		}
		.arrow2:after, .arrow2:before {
			content: '';
			position: absolute;
			font: 12px "Consolas", monospace;
			font-style: normal;
			pointer-events: none;
			display: none\9;
			left: 5px;
		}
		.arrow2:before { content:'\25BC'; bottom:4px; }
		.double:after { content:'\25B2'; top:-1px;  }
		.double:before { content:'\25BC'; bottom:-1px; }
	}
	.gui-textarea, .gui-textarea:focus {
		height: 199px !important;
	}
	button {
		margin-top: 15px;
		float: right;
		color: #fff;
		background-color: darken(@sepia, 20%);
		font-family: @mainFont;
	}
}


.white-popup {
	position: relative;
  	background: @white;
  	padding: 20px;
  	width: auto;
  	max-width: 500px;
  	margin: 20px auto;
	h3 {
		color: @red;
		margin: 0;
	}
	h6 {
		color: @black;
		border-bottom: solid 1px @black;
		padding-bottom: 15px;
		margin-top: 0;
		margin-bottom: 15px;
	}
	p {
		color: @black;
	}
}



/*Page Content*/
.page-content {
	padding: 30px 0;
	img {
		max-width: 100%;
	}
	h1, h2, h3, h4, h5, h6 {
		color: @oliveGreen;
	}
	p {
		color: @darkSepia;
	}
	h4 {
		margin-top: 0;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
	ul, ol {
		li {
			color: @darkSepia;
		}
	}
	hr {
		border-top: solid 1px @sepia;
	}
}

/*News Content*/
.news-list {
	.news-list-content {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: solid 1px @sepia;
		h5, h6 {
			color: @oliveGreen;
		}
		h5 {
			margin-top: 0;
			margin-bottom: 10px;
		}
		h6 {
			margin-bottom: 0;
			font-size: 16px;
		}
		p {
			margin-bottom: 0;
			color: @darkSepia;
			a {
				margin-left: 5px;
			}
			a:link, a:visited {
				color: @softBlue;
				text-decoration: underline;
			}
			a:hover {
				color: @softBlue;
				text-decoration: none;
			}
		}
		.news-thumb {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}
	}
	.article-content {
		border-bottom: none;
		p {
			margin-bottom: 20px;
			a {
				margin-left: 0;
			}
		}
		img, iframe {
			margin-bottom: 15px;
		}
		.news-share {
			margin-top: -10px;
			margin-bottom: 30px;
		}
	}
	.pagination {
		margin: 15px 0;
		float: right;
		ul {
			li {
				list-style: none;
				float: left;
				margin-right: 10px;
				margin-bottom: 10px;
				padding: 5px;
				a:link, a:visited {
					color: @oliveGreen;
				}
				a:hover {
					color: lighten(@oliveGreen, 10%);
				}
			}
			li.active {
				padding: 5px 10px;
				border-radius: 3px;
				background-color: darken(@sepia, 10%);
				a:link, a:visited, a:hover {
					color: @white;
				}
			}
		}
	}
}

.sidebar-news {
	h6 {
		color: @oliveGreen;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
	p {
		a:link, a:visited {
			color: @softBlue;
			text-decoration: underline;
		}
		a:hover {
			color: @softBlue;
			text-decoration: none;
		}
	}
	.sidebar-news-list {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: solid 1px @sepia;
		h5 {
			color: @oliveGreen;
			margin: 0;
			font-size: 14px;
			line-height: 16px;
		}
		h6 {
			color: @sepia;
			margin: 0;
			font-size: 13px;
		}
		.news-thumb {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}
	}
	.sidebar-news-list:hover {
		opacity: 0.8;
		filter: alpha(opacity=80);
	}
}


/*Contact Content*/
.sidebar {
	h6 {
		color: @oliveGreen;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
	p {
		a:link, a:visited {
			color: @softBlue;
			text-decoration: underline;
		}
		a:hover {
			color: @softBlue;
			text-decoration: none;
		}
	}
	a.btn {
		width: 100%;
		padding: 10px 15px;
		border-radius: 5px;
		background-color: darken(@sepia, 20%);
		color: @white;
		margin-bottom: 15px;
		img {
			margin-right: 10px;
		}
	}
	a.btn:link {
		color: @white;
	}
	a.btn:visited {
		color: @white;
	}
	a.btn:hover {
		color: @white;
		background-color: darken(@sepia, 30%);
	}
}

/*Project Content*/
.project-list {
	a {
		.project-thumb {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}
		.project-thumb-caption {
			min-height: 80px;
			padding: 15px;
			background-color: lighten(@sepia, 10%);
			margin-bottom: 30px;
			h5 {
				margin: 0;
				height: 40px;
				color: @oliveGreen;
				font-size: 14px;
				line-height: 16px;
			}
			h6 {
				margin: 0;
				color: darken(@sepia, 30%);
				font-size: 13px;
			}
		}
	}
	a:hover {
		.project-thumb, .project-thumb-caption {
			opacity: 0.8;
			filter: alpha(opacity=80);
		}
	}
	.pagination {
		margin: 15px 0;
		float: right;
		ul {
			li {
				list-style: none;
				float: left;
				margin-right: 10px;
				margin-bottom: 10px;
				padding: 5px;
				a:link, a:visited {
					color: @oliveGreen;
				}
				a:hover {
					color: lighten(@oliveGreen, 10%);
				}
			}
			li.active {
				padding: 5px 10px;
				border-radius: 3px;
				background-color: darken(@sepia, 10%);
				a:link, a:visited, a:hover {
					color: @white;
				}
			}
		}
	}
}


/*Products Content*/
.product-list {
	.product-thumb {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
	.product-list-content {
		min-height: 80px;
		margin-bottom: 30px;
		h5 {
			text-transform: uppercase;
			color: @oliveGreen;
		}
		a.btn {
			padding: 10px 15px;
			border-radius: 5px;
			background-color: darken(@sepia, 20%);
			color: @white;
			margin-bottom: 15px;
		}
		a.btn:link {
			color: @white;
		}
		a.btn:visited {
			color: @white;
		}
		a.btn:hover {
			color: @white;
			background-color: darken(@sepia, 30%);
		}
		.logo-product {
			margin: 15px 0;
		}
	}
}

.product-row {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: solid 1px @sepia;
	h5 {
		margin-top: 0;
		a:link, a:visited {
			color: @oliveGreen;
		}
		a:hover {
			color: @oliveGreen;
		}
	}
	a.btn {
		padding: 10px 15px;
		border-radius: 5px;
		background-color: darken(@sepia, 20%);
		color: @white;
		margin-bottom: 15px;
	}
	a.btn:link {
		color: @white;
	}
	a.btn:visited {
		color: @white;
	}
	a.btn:hover {
		color: @white;
		background-color: darken(@sepia, 30%);
	}
	img {
		margin-top: 7px;
	}
}

.product-sidebar-bg {
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #c5e3b8;
}

.product-sidebar {
	h6 {
		color: @oliveGreen;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
}

.product-category {
	margin-bottom: 15px;
}
.product-category-head {
	position: relative;
	background-color: lighten(#9EBC8E, 10%);
	padding: 10px 30px 6px;
	cursor: pointer;
	i {
		position: absolute;
		right: 30px;
		font-size: 21px;
		margin-top: 0px;
	}
	h6 {
		margin: 0px;
		text-transform: none;
		padding-bottom: 3px;
		padding-right: 30px;
		text-transform: uppercase;
	}
}
.product-category-content {
	display: none;
	background-color: @white;
	padding: 20px 30px;
	ul {
		li {
			list-style: none;
			text-transform: capitalize;
			margin-bottom: 10px;
			a:link, a:visited {
				color: darken(@sepia, 20%);
			}
			a:hover {
				color: darken(@sepia, 20%);
				text-decoration: underline;
			}
			.fa {
				margin-right: 5px;
			}
			ul {
				margin-left: 25px;
    			margin-top: 10px;
			}
		}
		li:last-child {
			margin-bottom: 0;
		}
	}
}
.product-category-selected {
	background-color: @sepia !important;
}
.product-category-selected i,  .faq-selected h6 {
	color: #fff !important;
}
.product-category-default {
	display: block;
}

.product-content {
	img {
		max-width: 100%;
		margin-bottom: 20px;
	}
	a.btn {
		padding: 10px 15px;
		border-radius: 5px;
		background-color: darken(@sepia, 20%);
		color: @white;
		margin-bottom: 15px;
	}
	a.btn:link {
		color: @white;
	}
	a.btn:visited {
		color: @white;
	}
	a.btn:hover {
		color: @white;
		background-color: darken(@sepia, 30%);
	}
	ol {
		margin-left: 15px;
		margin-bottom: 30px;
		li {
			ol, ul {
				margin-left: 20px;
			}
		}
	}
	table {
		margin-bottom: 30px;
		tr {
			border-left: solid 1px @oliveGreen;
			td {
				padding: 0 15px;
				border-right: solid 1px @oliveGreen;
			}
		}
	}
	.product-gallery-list {
		.product-gallery-container {
			margin-bottom: 30px;
			.product-gallery-image {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				height: 200px;
			}
			.product-gallery-title {
				padding: 10px 15px;
				background-color: @sepia;
				p {
					color: @oliveGreen;
					margin: 0;
				}
			}
		}
		.product-gallery-container:hover {
			opacity: 0.8;
			filter: alpha(opacity=80);
		}
	}
}

body.sport {
	.site-wrapper {
		background-position: top right;
		background-repeat: repeat-y;
		background-size: 100% 100%;
		.product-category-head {
			i {
				color: @oliveGreen;
			}
			h6 {
				color: @oliveGreen;
			}
		}
		.product-category-selected {
			background-color: #9EBC8E !important;
		}
		.product-category-selected i,  .faq-selected h6 {
			color: @oliveGreen !important;
		}
	}
}

.sub-navigation {
	margin-top: 30px;
	background-color: @white;
	padding: 20px 30px;
	h6 {
		margin-top: 0;
		margin-bottom: 30px;
	}
	ul {
		li {
			list-style: none;
			text-transform: capitalize;
			margin-bottom: 10px;
			padding-bottom: 10px;
			border-bottom: solid 1px @oliveGreen;
			a:link, a:visited {
				color: darken(@sepia, 20%);
			}
			a:hover {
				color: darken(@sepia, 20%);
				text-decoration: underline;
			}
		}
		li:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
		li.active {
			font-weight: 700;
		}
	}
}

.info-box {
	margin-top: 30px;
	background-color: @white;
	padding: 20px 30px;
	h6 {
		margin-top: 0;
		margin-bottom: 15px;
	}
	p {
		font-size: 13px;
		line-height: 17px;
		margin-bottom: 15px;
	}
	ul {
		margin-left: 20px;
		margin-bottom: 15px;
		li {
			font-size: 13px;
			line-height: 17px;
		}
	}
}

.gallery-image {
	height: 140px; 
	background-size: cover; 
	background-position: center center; 
	background-repeat: no-repeat;
	display: block;
	margin-bottom: 30px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	h1 {
		font-size: @font-size-h1 ;
		line-height: floor((@font-size-h1 * @line-height-base) - 10) ;
	}
	h2 {
		font-size: @font-size-h2 ;
		line-height: floor((@font-size-h2 * @line-height-base)) ;
	}
	h3 {
		font-size: @font-size-h3 ;
		line-height: floor((@font-size-h3 * @line-height-base)) ;
	}
	h4 {
		font-size: @font-size-h4 ;
		line-height: floor((@font-size-h4 * @line-height-base)) ;
	}
	h5 {
		font-size: @font-size-h5 ;
		line-height: floor((@font-size-h5 * @line-height-base)) ;
	}
	h6 {
		font-size: @font-size-h6 ;
		line-height: floor((@font-size-h6 * @line-height-base)) ;
	}
	p {
		font-size: @font-size-base ;
		line-height: @line-height-computed ;
		margin-bottom: 20px ;
	}
	ul li, ol li {
		font-size: @font-size-base ;
		line-height: @line-height-computed ;
	}
	
	.page-content {
		padding: 60px 0;
		h4 {
			margin-bottom: 40px;
		}
	}
	
	.background-about {
		background-image: url(../images/about.jpg); 
		background-position: left -60% top; 
		background-size: contain; 
		background-repeat: no-repeat;
	}
	
	/*News Content*/
	.news-list {
		.news-list-content {
			.news-thumb {
			}
		}
	}
	
	body.sport {
		.site-wrapper {
			background-image: url(../images/sport-bg.png);
			background-size: 38% 100%;
		}
	}
	
	.product-sidebar-bg {
		padding-top: 0;
		padding-bottom: 0;
		background-color: transparent;
	}
	
	.modal-dialog {
		width: 800px;
	}
	
	.modal-header {
		background-color: @sepia;
		color: @oliveGreen;
		border-radius: 6px 6px 0 0;
	}
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.background-about {
		background-position: left -25% top;
	}
	body.sport {
		.site-wrapper {
			background-size: 38% 100%;
		}
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	.background-about {
		background-position: left top;
	}
	body.sport {
		.site-wrapper {
			background-size: 38% 100%;
		}
	}
}

@media (min-width: 1600px) {
	body.sport {
		.site-wrapper {
			background-size: 43% 100%;
		}
	}
}