/* LESS Document */
@fullWidth : 100%;

/* COLORS */
@white: #fff;
@black: #000;
@red: #ef412e;
@softGreen: #D3E6C7;
@softBlue: #74B5B5;
@sepia: #B2AE9A;
@darkSepia: #716558;
@brown: #3B2314;
@oliveGreen: #333417;
@pgnGrey: #4e606e;
@pgnBlue: #00adef;
@pgnLightBlue: #dae3e7;

@cbPurple: #492f80;
@cbBrown: #4e2a1a;
@cbLightBrown: #b2661a;

@ausBlue: #3cb6ce;
@ausNavy: #003150;
@ausGrey: #666666;
@bodyColor : #fff;

/* FONTS */
@mainFont : 'Lato', sans-serif;
@mainFontBold : 'Lato', sans-serif;

/* TYPOGRAPHY */
@font-size-base:          15px;

@font-size-h1:            floor((@font-size-base * 3.2)); // ~48px
@font-size-h2:            floor((@font-size-base * 2.4)); // ~36px
@font-size-h3:            ceil((@font-size-base * 1.85)); // ~28px
@font-size-h4:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h5:            ceil((@font-size-base * 1.4)); // ~21px
@font-size-h6:            ceil((@font-size-base * 1.2)); // ~18px

@line-height-base:        1.4; // 20/14
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px


/* FlexSlider */
@font-path: "../fonts";