.page-head {
	margin-top: 81px;
	background-color: @sepia;
	padding: 15px 0;
	color: @oliveGreen;
	border-bottom: solid 8px #D9C48D;
	h2 {
		font-weight: 700;
		text-transform: uppercase;
		margin: 0;
	}
	ul {
		li {
			float: left;
			list-style: none;
			a:link, a:visited {
				color: lighten(@oliveGreen, 20%);
			}
			a:hover {
				color: lighten(@oliveGreen, 20%);
				text-decoration: underline;
			}
		}
		li:after {
			content: '/';
			margin: 0 15px;
		}
		li:last-child:after {
			content: '';
		}
	}
}


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.page-head {
		margin-top: 158px;
		padding: 30px 0;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.page-head {
		margin-top: 95px;
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
}

@media (min-width: 1600px) {
	
}


